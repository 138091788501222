<template>
  <div class="cont" ref="cont">
        <span  :class="'editor-btn '" @click="toggleEdit()">
            <font-awesome-icon v-if="editor.loading" :icon="['fal','spinner']" pulse/>
            <font-awesome-icon v-else :icon="['fas','pencil']"/></span>
    <div v-if="editor.active " class='editor-option' @mouseenter="edit($event)" @mouseleave="edit($event)" @click="edit($event)" >
            <span class='btn new-table' @click="showEditName(0)">
                <font-awesome-icon :icon="['fas','plus']"/>
            </span>
      <span class='btn rename ' :class="{enabled:(getActive!==null),disabled:(getActive===null)}" @click="showEditName(1)">
                <font-awesome-icon :icon="['fas','font']"/>
            </span>
      <span class='btn clone ' :class="{enabled:(getActive!==null),disabled:(getActive===null)}" @click="clone()">
                <font-awesome-icon :icon="['fas','clone']"/>
            </span>

      <span class='btn shape ' :class="{enabled:(getActive!==null),disabled:(getActive===null)}" >
                <span  class='button-option ' @click="changeShape()"><font-awesome-icon :icon="['fas',getShape]"/></span>
            </span>
      <span class='btn color' @click="editor.paint=!editor.paint">
                <font-awesome-icon :icon="['fas','tint']"/>
                <div class="button-option main-card mb-2 card color-palette" v-if="editor.paint&&editor.active">
                    <div class="card-body" >
                        <div v-for="(color,index) in palette" :key="index" >
                            <div  class=" swatch-holder swatch-holder-lg " :style="{backgroundColor:color}" @click="changeColor(color,index)"></div>
                        </div>
                    </div>
                </div>
            </span>
      <span class='btn seats ' :class="{enabled:(getActive!==null),disabled:(getActive===null)}"
            @click="showConfig()">
                <font-awesome-icon :icon="['fas','cogs']"/>
      </span>
      <span class='btn trash ' :class="{enabled:!(getActive===null),disabled:(getActive===null)}" @click="deleteTable">
                <font-awesome-icon :icon="['fas','trash']"/>
            </span>
    </div>
    <div class="floor" ref="floor" :style="{backgroundColor:editor.background}"  @click.self="disableEdit()" id="floor">
      <vue-drag-resize v-for="(item,index) in tables" :key="item.name" :preventActiveBehavior="!editor.active"
                       :minw="50" :minh="50"
                       :parentLimitation="true" :isActive="item.active" :isDraggable="item.draggable" :isResizable="item.resizable"
                       :x="item.left" :y="item.top" :w="item.width" :h="item.height"
                       @dragstop="calculatePos($event,index)" @deactivated="setInactive(index)" @activated="setActive(index)"
                       @resizestop="resize($event,index)" @resizing="alignElements($event,index)" :class="{drag:item.active}"
      >
        <div  :class="['room-table',item.type]" :style="{backgroundColor:item.color}" >
          <v-row no-gutters justify="center" align="start" class="w-100">
                        <span class="table-total px-1">
                            {{300 | currency}}
                        </span>
          </v-row>
          <v-row no-gutters justify="space-between" align="center" class="w-100">
            <v-col cols="4" align-self="center" class="px-1">
                            <span  class="table-timer" >
                                <v-progress-circular
                                    color="green"
                                    width="10"
                                    size="20"
                                    rotate="275"
                                    value="30"
                                />
                            </span>
            </v-col>
            <v-col cols="4" align-self="center" align="center">
                            <span class="table-name">
                                {{item.name}}
                            </span>
            </v-col>
            <v-col cols="4" align-self="center" align="end" class="px-1">
                            <span  class="table-state" >
                                <font-awesome-icon :icon="['fal','lock-alt']"/>
                            </span >
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" align="end" class="w-100">
                        <span class="table-client px-1">
                            {{item.maxParties}}
                        </span>
          </v-row>
          <span class="room-table-filter" style="height:35%;">

                    </span>

        </div>
      </vue-drag-resize>
    </div>

    <!-- TABLE NAME DIALOG -->

    <v-dialog v-model="dialog.name" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>{{ dialog.edit ? $t('settings.lang_editTableName') : $t('settings.lang_addTable') }}</v-card-title>

        <v-card-text>
          <v-layout wrap>
            <v-flex md12>
              <v-text-field :loading="loadingEditName" type="text" v-model="dialog.tableName" @keypress="preventSpecialChars" :error="dialog.tableNameValide" :error-messages="dialog.tableNameMessage" :label="$t('generic.lang_table')"/>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-btn @click="dialog.name = false" text color="error">{{$t("generic.lang_cancel")}}</v-btn>
          <v-spacer/>
          <v-btn color="success" :disabled="!dialog.tableName" @click="editName()">{{ $t("generic.lang_create") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CONFIG DIALOG -->
    <v-dialog v-model="dialog.config" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>{{ $t('settings.lang_maxNumberOfPartiesAndPersons') }}</v-card-title>
        <v-card-text>
          <v-layout wrap>
            <v-flex md12>
              <v-text-field type="number" v-model="dialog.maxPartiesField" :rules="[v=> (parseInt(v)>0) || $t('generic.lang_mustBeGreater0')]" :min="1" :label="$t('generic.lang_maxParties')"/>
            </v-flex>
            <v-flex md12>
              <v-text-field type="number" v-model="dialog.maxPersons" :rules="[v=> (parseInt(v)>0) || $t('generic.lang_mustBeGreater0')]" :min="1" :label="$t('generic.lang_maxPersons')"/>
            </v-flex>
            <v-flex md12>
              <v-checkbox :label="$t('generic.lang_dontAskForParties')" v-model="dialog.dontAskForParties">
              </v-checkbox>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-btn @click="dialog.config = false" text color="error">{{ $t('generic.lang_cancel') }}</v-btn>
          <v-spacer/>
          <v-btn color="success" @click="editConfig()">{{$t('generic.lang_apply')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div id="onScreenKeyboardDiv">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
    <v-dialog v-model="editor.loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <p class="white--text text-center" style="margin-bottom: 5px;">{{this.$t('datatables.lang_Processing')}}</p>

                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                > </v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import VueDragResize from 'vue-drag-resize'
import {ENDPOINTS} from "../../../../config";
import mixin from "../../../../mixins/KeyboardMixIns";
import {library} from '@fortawesome/fontawesome-svg-core';

import {fal} from '@fortawesome/pro-light-svg-icons';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {Events} from "../../../../plugins/events";
import validation from "../../../../mixins/validation/validation";

library.add(
    fal,fas
)
export default {
  name: "RoomFloorEditorComponent",
  components:{
    VueDragResize,FontAwesomeIcon
  },
  mixins:[mixin,validation],
  watch: {
    showCreateTableDialog() {
      this.tableName = "";
    }
  },
  data(){
    return{
      ENDPOINTS,
      id:null,
      editMod:0,
      loadingEditName:false,
      dialog:{
        edit:true,
        name:false,
        config: false,
        dontAskForParties: false,
        tableName:"",
        tableNameMessage:"",
        tableNameValid:true,
        maxPersons:2,
        maxPartiesField:5,
        partyName: "Partei 1",
      },
      posType:"gastro",
      editor:{
        shape:"rect",
        background:"#098650",
        isEditing:false,
        active:false,
        loading:false,
        paint:false,
        add:false,
        rename:false,
      },
      tables:[],
      room:{
        name:"",
        width:1,
        dontAskForParties: false,
      },
      palette:[
        '#7997e4',
        '#989fa4',
        '#75d6a4',
        '#5cc4ff',
        '#f9ce66',
        '#e46785',
        '#7c7a88',
        '#a182ad',
        '#71757a',
      ],
      bgPalette:[
        '#3f6ad8',
        '#6c757d',
        '#3ac47d',
        '#16aaff',
        '#f7b924',
        '#d92550',
        '#444054',
        '#794c8a',
        '#343a40',
      ],
    }
  },
  computed:{
    getActive(){
      for (let i = 0, l = this.tables.length; i < l; i++) {
        let item = this.tables[i];
        if (item.active) {
          return i;
        }
      }
      return null;
    },
    getShape(){
      if(this.editor.active && this.getActive!==null){
        let index=this.getActive;
        return (this.tables[index].type==='round')?"square":"circle";
      }else if(this.editor.active&&this.getActive===null){
        return (this.editor.shape==='round')?"circle":"square";
      }
      return "circle";
    },

  },
  methods: {
    alignElements(event,index){
      this.tables[index].lineHeight=event.height;
    },
    resize(event,index) {
      this.tables[index].width = event.width;
      this.tables[index].height = event.height;
    },
    disableEdit(){
      this.editor.isEditing=false;
      this.editor.paint=false;
    },
    edit(event){
      if(event.type==="mouseenter"){
        this.editor.isEditing=true;
      }else if(event.type==="mouseleave"){
        this.editor.isEditing=false;
      }else {
        this.editor.isEditing=true;
      }
    },
    toggleEdit(){
      if(!this.editor.loading){
        this.editor.active=!this.editor.active;
        if(!this.editor.active){
          for (let i = 0, l = this.tables.length; i < l; i++) {
            this.tables[i].draggable=false;
            this.tables[i].resizable=false;
            this.tables[i].active=false;
          }
          this.updateRoomLayout();
        }
      }
    },
    calculatePos(event,index){

      this.tables[index].top = event.top;
      this.tables[index].left = event.left;
    },
    getRoomLayout(){
      this.axios.post('get/settings/roomDetails/',{
        roomID:parseInt(this.$route.params.id),
      }).then(res=>{
        if(res.status===200){
          if(res.data.formfillData.textFields.interior_object!=='{}'){
            let interior=JSON.parse(res.data.formfillData.textFields.interior_object);
            let parentWidth=this.$refs.floor.clientWidth,parentHeight=this.$refs.floor.clientHeight
            for(let i=0;i<interior.tables.length;i++){
              interior.tables[i].left= (parseFloat(interior.tables[i].left/100) *  parseFloat(parentWidth));
              interior.tables[i].top = (parseFloat(interior.tables[i].top/100)  * parseFloat(parentHeight));
              interior.tables[i].width=(parseFloat(interior.tables[i].width/100)  * parseFloat(parentWidth));
              interior.tables[i].height=(parseFloat(interior.tables[i].height/100)  * parseFloat(parentHeight));
            }
            this.editor.background=interior.background;
            this.tables=interior.tables;
            this.room.dontAskForParties=res.data.formfillData.textFields.dontAskForParties;
          }
        }
      }).catch(err=>{

        //console.log("err")
      });
    },
    updateRoomLayout(){

      let interior={
        background:this.editor.background,
        tables:JSON.parse(JSON.stringify(this.tables)),
      };

      let parentWidth=this.$refs.floor.clientWidth,parentHeight=this.$refs.floor.clientHeight
      for(let i=0;i<interior.tables.length;i++){
        interior.tables[i].left= (parseFloat(interior.tables[i].left).toFixed(2)/ parseFloat(parentWidth).toFixed(2)) *100;
        interior.tables[i].top = (parseFloat(interior.tables[i].top).toFixed(2)/parseFloat(parentHeight).toFixed(2)) *100;
        interior.tables[i].width=(parseFloat(interior.tables[i].width).toFixed(2)/ parseFloat(parentWidth).toFixed(2)) *100;
        interior.tables[i].height=(parseFloat(interior.tables[i].height).toFixed(2)/parseFloat(parentHeight).toFixed(2)) *100;
      }
      this.editor.loading=true;
      this.axios.post('update/settings/roomLayout/',{
        roomID:this.id,
        interior_object:interior,
      }).then(res=>{
        this.editor.loading=false;
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_savedSuccessfully'),
          color: "success"
        });
      }).catch(err=>{
        this.editor.loading=false;
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorWhileSaving'),
          color: "error"
        });
      });
    },
    handleClick(event,index){
      if(!this.editor.active){
        //this.selectTable()
      }
    },
    setActive(index){
      if(this.editor.active){
        for (let i = 0, l = this.tables.length; i < l; i++) {
          if (i === index) {
            this.tables[i].active = true;
            this.tables[i].draggable=true;
            this.tables[i].resizable=true;
            continue;
          }
          this.tables[i].draggable=false;
          this.tables[i].resizable=false;
          this.tables[i].active = false;

        }
      }
    },
    setInactive(index){
      if(!this.editor.isEditing){
        this.tables[index].active=false;
      }
    },
    addTable(name){
      if(this.editor.active){
        this.tables.push({
          width:100,height:100,
          top:100,left:100,
          name:name,maxParties:5,dontAskForParties: this.room.dontAskForParties,
          maxPersons:4,uuid:'',
          type:this.editor.shape,color:'#5cc4ff',
          user:0,total:0,
          active:false,draggable:false,resizable:false,lineHeight:100,
        });
        this.setActive(this.tables.length-1);
      }
    },
    deleteTable(){
      if(this.editor.active && this.getActive!==null){
        this.$swal({
          title: this.$t('generic.lang_confirmDeleting'),
          text: this.$t('generic.lang_deleteSelectedTableText'),
          icon: "warning",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            let index=this.getActive;
            this.tables.splice(index, 1);
          },
          allowOutsideClick: () => !this.$swal.isLoading,
        });
      }
    },
    changeShape(){
      if(this.editor.active && this.getActive!==null) {
        let index=this.getActive;
        this.tables[index].type=(this.tables[index].type==="rect")?"round":"rect";
      }else{
        this.editor.shape=(this.editor.shape==="rect")?"round":"rect";
      }
    },
    changeColor(color,key){
      if(this.editor.active && this.getActive!==null) {
        let index=this.getActive;
        this.tables[index].color=color;
      }
      if(this.getActive===null){
        this.editor.background=this.bgPalette[key];
      }
    },
    async clone(){
      if(this.editor.active && this.getActive!==null){
        this.editor.loading=true;
        let index=this.getActive;
        let clone = {...(this.tables[index])};
        let name='';
        if(isNaN(this.tables[index].name)){
          if(!this.tables[index].name.includes('_dup')){
            clone.name=this.tables[index].name+'_dup';
          }
        }else{
          clone.name=parseInt(this.tables[index].name)+1;
        }

        name=clone.name;
        while(await this.isDup(name)){
          if(isNaN(name)){
            let dupCntr=name.slice(name.indexOf('_dup')+4)
            dupCntr=(dupCntr!=="")?parseInt(dupCntr)+1:"1"
            name=this.tables[index].name.slice(0,this.tables[index].name.indexOf('_dup'))+'_dup'+dupCntr;
          }else{
            name=parseInt(name)+1;
          }
        }

        clone.name=name;
        clone.uuid='';
        clone.user=0;
        clone.total=0;
        clone.top=clone.top+10;
        clone.left=clone.left+10;
        this.editor.loading=false;
        this.tables.push(clone);
        this.setActive(this.tables.length-1);

      }
    },
    async isDup(name){
      let state=true;
      //check if table already in the current room
      if(this.tableExist(name))
      {
        return true
      }
      //check if table is exiting in other rooms
      await this.axios.post(ENDPOINTS.POS.GASTRO.UNIQUE,{
        tableNo:name,
      }).then(res=>{
        state=(res.data.status!=='SUCCESS')
      })
      return state;
    },
    tableExist(name) {
      for (let i = 0, l = this.tables.length; i < l; i++) {
        if (this.tables[i].name.toString() === name.toString()) {
          return true;
        }
      }
      return false;
    },
    showEditName(mode) {
      if (mode === 0) {
        this.dialog.edit=false;
        this.dialog.name = true;
        this.editMod = 0
      } else if (this.editor.active && this.getActive !== null) {
        this.dialog.edit=true;
        this.dialog.name = true;
        this.editMod = 1
        this.dialog.tableName = this.tables[this.getActive].name;
      }
      this.dialog.tableNameValide = true;
      this.dialog.tableNameMessage = "";
    },
    editName(mode) {
      if (this.tableExist(this.dialog.tableName)) {
        this.dialog.tableNameValide = false;
        this.dialog.tableNameMessage = this.$t('generic.lang_tableAlreadyExists')
        return;
      }

      if(this.editMod===0){
        this.loadingEditName=true
        this.axios.post(ENDPOINTS.POS.GASTRO.UNIQUE,{
          tableNo:this.dialog.tableName,
        }).then(res=>{
          if(res.data.status==='SUCCESS'){
            this.dialog.tableNameValide=true;
            this.dialog.tableNameMessage="";
            this.addTable(this.dialog.tableName)
            this.dialog.tableName="";
            this.dialog.name=false;
          }else{
            this.dialog.tableNameValide=false;
            this.dialog.tableNameMessage=res.data.code
          }
        }).catch(err=>{
          this.dialog.tableNameValide= false;
        }).finally(()=>{
          this.loadingEditName=false
        })
      }else{
        let index=this.getActive;
        this.loadingEditName=true
        this.axios.post(ENDPOINTS.POS.GASTRO.UNIQUE,{
          tableNo:this.dialog.tableName,
        }).then(res=>{
          if(res.data.status==='SUCCESS'){
            this.dialog.tableNameValide=true;
            this.dialog.tableNameMessage="";
            this.tables[index].name=this.dialog.tableName;
            this.dialog.tableName="";
            this.dialog.name=false;
          }else{
            this.dialog.tableNameValide=false;
            this.dialog.tableNameMessage=res.data.code
          }
        }).catch(err=>{
          this.dialog.tableNameValide= false;
        }).finally(()=>{
          this.loadingEditName=false
        })
      }
    },
    showConfig(){
      if(this.editor.active && this.getActive!==null){
        this.dialog.config = true;
        this.dialog.maxPartiesField=this.tables[this.getActive].maxParties;
        this.dialog.maxPersons=this.tables[this.getActive].maxPersons;
        this.dialog.dontAskForParties = this.tables[this.getActive].dontAskForParties;
      }
    },
    editConfig() {
      let index=this.getActive;
      if(parseInt(this.dialog.maxPartiesField)>0){
        this.tables[index].maxParties = parseInt(Number(this.dialog.maxPartiesField));
        this.tables[index].maxPersons = parseInt(Number(this.dialog.maxPersons))
        this.tables[index].dontAskForParties = this.dialog.dontAskForParties;
        this.dialog.maxPartiesField = 1;
        this.dialog.maxPersons=1;
        this.dialog.maxParties = false;
        this.dialog.dontAskForParties = false;
        this.dialog.config = false;
      }
    },


  },
  mounted() {
    this.id=parseInt(this.$route.params.id);
    this.getRoomLayout();
  }

}

</script>

<style scoped>
.cont{
  width:100%;
  height: 768px;
}
.room-table{
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
}
.rect{
  border-radius: 10px;
}
.round{
  border-radius: 600px;
}
.floor{
  position: absolute;
  width: 100%;
  height: 100%;
}

.room-table-filter{
  display: block;
  background: rgba(0,0,0,0.2);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0px 0px 3px 3px;
}
.table-name{
  overflow: hidden;
  font-size: 16px;
  color:white;
  user-select: none;
  overflow-wrap: break-word;
}
.table-client{
  text-align: center;
  height: 20px;
  min-width: 20px;
  font-size: 14px;
  border-radius: 50%;
  margin-bottom: 5px;
  background: black;
  color: white;
  opacity: 0.2;
  z-index: 0;
}
.table-total{
  margin-top: 5px;
  text-align: center;
  height: 20px;
  border-radius: 600px;
  min-width: 40px;
  background: black;
  color: white;
  opacity: 0.2;
  z-index: 0;
  font-size: 14px;
  user-select: none;
}
.table-state{
  text-align: center;
  height: 20px;
  width:30px;
  z-index: 0;
  font-size: 20px;
  color:white;
  user-select: none;
}
.table-timer{
  text-align: center;
  height: 20px;
  width: 30px;
  z-index: 0;
  font-size: 20px;
  color:white;
  user-select: none;
}
.editor-btn{
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  font-size: 20px;
  margin: 8px;
  line-height: 32px;
  width: 32px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  background-color: lightgrey;
  border: solid 1px rgba(0,0,0,0.2);
}
.editor-option{
  position: absolute;
  top: 0;
  right: 40px;
  margin: 8px;
  line-height: 34px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  background: rgba(255,255,255,0.5);
  z-index: 10;
}
.editor-option btn{
  position: relative;
  width: 32px;
  display: inline-block;
  cursor: pointer;
  margin-right: -4px;
  border-right: solid 1px rgba(0,0,0,0.2);
  transition: all 150ms linear;
}
.activated{
  background: #444;
  border-color: transparent;
  color: white;
}

.color-palette{
  position: absolute;
}

.swatch-holder-lg{
  width: 48px;
  height: 48px;
  line-height: 48px;
}
.swatch-holder{
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin: 5px 5px 0;
  -webkit-transition: all .2s;
  transition: all .2s;
  opacity: .7;
  display: inline-block;
  border-radius: 30px;
}
.drag{
  cursor: move;
}
.bg-default{
  background-color: #098650;
}

</style>
